import { GatsbyImage } from 'gatsby-plugin-image';
import { ImageProps } from 'libs/growth-platform-brand-system-v2/src/types/module';
import { createSchema } from 'morphism';

type PixelDensityProps = 'default' | 'image' | 'image_with_text';

const prepareSrcSetFromGatsby = (
  fallback: any,
  pixelDensity: PixelDensityProps = 'default',
) => {
  const _fallbackSrcset = fallback.srcSet;
  const _fallback = { ...fallback };

  let dpr = 1.2;
  if (pixelDensity === 'image') dpr = 1.5;
  if (pixelDensity === 'image_with_text') dpr = 2;

  _fallback.srcSet = _fallbackSrcset.replace(
    /dpr=([\d.]+)/g,
    (match: any, p1: any) => {
      const newDpr = parseFloat(p1) * dpr;
      return `dpr=${newDpr.toFixed(2)}`;
    },
  );

  return _fallback;
};

export const schemaImage = createSchema<ImageProps & { image: any }, any>({
  image: {
    path: ['gatsbyImageData', 'pixelDensity'],
    fn: ({ gatsbyImageData, pixelDensity }: any, source: any) => {
      const newValue =
        gatsbyImageData && JSON.parse(JSON.stringify(gatsbyImageData));
      const fallback = newValue?.images?.fallback && {
        ...newValue?.images?.fallback,
      };

      if (!fallback && newValue?.images?.sources?.[0]) {
        return {
          ...newValue,
          images: {
            ...newValue.images,
            fallback: newValue?.images?.sources?.[0],
          },
        };
      }
      if (fallback?.srcSet) {
        newValue.images.fallback = prepareSrcSetFromGatsby(
          fallback,
          pixelDensity,
        );
      }
      return newValue;
    },
  },
  alt: {
    path: 'alt',
    fn: (propertyValue: string, source: any) => {
      return propertyValue || source?.altDefault || '';
    },
  },
  src: {
    path: 'gatsbyImageData',
    fn: (propertyValue: any, source: any) => {
      if (!propertyValue?.images?.fallback) {
        return source?.url;
      }
      return null;
    },
  },
  as: {
    path: 'gatsbyImageData',
    fn: (propertyValue: any) => {
      if (propertyValue?.images?.fallback || propertyValue?.images?.sources) {
        return GatsbyImage;
      }
      return 'img' as any;
    },
  },
  loading: {
    path: 'isHeader',
    fn: (propertyValue: any): string => {
      if (propertyValue) {
        return 'eager';
      }
      return 'lazy';
    },
  },
  width: 'width',
  height: 'height',
  srcSet: 'srcSet',
  sizes: 'sizes',
});
